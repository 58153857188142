@import "../../../variables";

.customBtn{
  cursor: pointer;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 1.75rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none !important;

    &.customBtnPrimary{
        color: white;
        background-color: $primary-purple-color;
        border-color: $primary-purple-color;
        padding: 0.375rem 0.75rem;

        &:hover{
            background-color: $purple-light;
            border-color: $purple-light;
        }

        &:disabled{
          color: white;
          border-color: $purple-light;
          background-color: $purple-light;
          cursor: initial;
        }
      }
  }