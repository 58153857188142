@import "../../variables";

.headerContainer{
    height: 3rem;
    display: flex;
    background-color: white;

    .headerLogoContainer{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        div {
            height: 100%; 
            padding: 5px; 
            display: flex; 
            justify-content: center;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

    }

    .clippedPart{
        background-color: $primary-purple-color;
        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        width: 234px
    }

    .headerInfoContainer{
        width: 72%;
        background-color: $primary-purple-color;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .headerInfo{
            color: white;
            font-weight: 100;
            display: flex;
            align-items: center;
            margin-right: 4rem;

            .userName {
                margin-left: 0.3rem;
                margin-right: 1rem;
            }

            .logOut {
                margin-left: 1rem;
                margin-right: 1rem;

                a{
                    color: white !important;
                    text-decoration: none !important;
                }
            }

            .delimiter {
                opacity: 0.3;
            }            
        }
    }
}