button.dropdown-toggle{
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  font-weight: 300;

  &:hover, &:active, &:focus{
      outline: none !important;
      border-color: transparent !important;
      background-color: transparent !important;
      box-shadow: none !important;
  }
}

.side-menu-container{
 position: relative;
  .side-menu{
      z-index: 99999999999999;
     position: absolute;
  }
}

.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;

  .bars-button{
      cursor: pointer;
  }
}