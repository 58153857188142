@import "../../variables";

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 7px;
    background-color: $gray-darker !important;
    z-index: 0;
    color: $primary-purple-color !important;

    .version {
        padding-left: 10px !important;
        margin-bottom: 5px;
    }
    
    .copyrightLabel {
        padding-right: 10px;
        text-align: right;
        margin-bottom: 5px;
    }
  } 
  