.container {
  margin: 2rem;
}
.antFormItem {
  margin-bottom: 10px;
}
.select {
  width: 100%;
}
.ant-form-item-control {
  max-width: 100% !important;
}
.numberFormat {
  width: 100%;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.formFieldsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  .formField {
    width: 21%;
    min-width: 300px;
    margin-right: 2%;
    .quoteIDInput {
      background-color: #c3c3c3;
      font-weight: 600;
    }
  }
}

.formFieldsWrapper :last-child {
  margin-bottom: 0px;
}

@media only screen and (max-width: 1330px) {
  .formFieldsWrapper {
    .emptySpaceFiller {
      display: none;
    }
  }
}

@media only screen and (max-width: 1122px) {
  .formFieldsWrapper:nth-child(3n + 0) {
    margin-right: 0px !important;
  }
}
