@import "../../../variables";

.cardBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .cardImg {
        height: 220px;
        width: 45%;
        border: thin solid gray;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .cardArrowAndInfo {
        display: flex;
        width: 50%;
        flex-direction: column;
        margin: 0 10px;
        justify-content: space-between;

        .changeCard {
            height: 45px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h2 {
                color: $primary-purple-color !important;
                margin: 0 !important;
                font-size: 20px;
            }

            .arrowContainer {
                display: flex;
                flex-direction: row;

                img:first-child {
                    margin-right: 10px;
                }

                .arrow {
                    height: 34px;
                    cursor: pointer;
                    &:hover {
                        padding: 1px;
                    }
                }
            }
        }

        .cardInfoDamage {
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: $primary-purple-color !important;
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0 !important;
            }
        }
    }

    .cardSeverty {
        height: 45px;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0 !important;
            color: $primary-purple-color !important;
            margin-left: 5px !important;
        }
    }

    .cardRla {
        height: 45px;
        // border: 1px solid black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        h2 {
            margin: 0 !important;
            color: $primary-purple-color !important;
            font-weight: bold;
        }
    }
}

.severity {
    display: flex;
    width: 30px;
    height: 22px;
    padding: 0px 8px;
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
    color: white !important;
    background: rgb(185, 185, 185);
    margin-right: 5px;
    opacity: 1 !important;
}
