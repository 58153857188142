.container {
  margin: 2rem;
}
.antFormItem {
  margin-bottom: 10px;
}
.select {
  width: 100%;
}
.ant-form-item-control {
  max-width: 100% !important;
}

.cancelButton {
  margin-left: 8px !important;
}
