@import "../../../src/variables";

.ActiveUploadsContainer {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 200px;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.InfoBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.InfoMessageContainer {
    font-size: 12px;
    margin-left: 5px;
}

.searchBoxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filterBoxContainer {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
}

.ant-slider-track {
    background-color: $primary-purple-color !important;
}