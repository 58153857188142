@import "../../variables";

.container {
  margin: 2rem;
}
.addFindingType {
  margin-bottom: 1rem;
}
.antFormItem {
  margin-bottom: 10px;
  .quoteID {
    font-weight: 600;
  }
}
.select {
  width: 100%;
}
.ant-form-item-control {
  max-width: 100% !important;
}
.bid {
  table-layout: auto;
  width: 100%;
  padding: 15px;
  border-spacing: 15px;
}
.costs {
  padding: 15px;
  border-spacing: 15px;
}
.input {
  margin-bottom: 5px;
  width: 150px !important;
  text-align: right;
  text-align-last: end;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-inner-spin-button {
    position: unset;
  }
}

.numberFormat {
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: PapayaWhip;
}

@media only screen and (max-width: 1300px) {
  .input {
    width: 90px !important;
  }

  .numberFormat {
    width: 115px !important;
  }
}
