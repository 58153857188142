.formContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //margin-bottom: 40px;

    & > div {
        margin: 0 10px 0 10px;
        min-width: 160px;
        max-width: 190px;
    }
}
