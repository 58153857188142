@import "~antd/dist/antd.css";
@import "variables";

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#root {
  background-color: $background-color;
  height: fit-content !important;
  min-height: 100vh !important;
}
* {
  box-sizing: border-box;
}
.Toastify__toast-container--top-right {
  margin-top: 4rem;
}
.Toastify__toast--default {
  color: $primary-purple-color !important;
}
.Toastify__close-button--default {
  color: $primary-purple-color !important;
}
.Toastify__progress-bar--default {
  background: $primary-purple-color !important;
}

.ant-popover-content {
  margin-left: 10px !important;
}
.ant-popover-arrow {
  right: 5px !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
  color: transparent !important;
}
.ant-popover-inner {
  margin-left: 3% !important;
}

.ant-popover {
  margin-left: 3.5% !important;
  top: 47px;
  transform-origin: 1860px -4px;
  width: 300px !important;
}

.ant-popover-hidden {
  display: none !important;
}

.stepsComponent {
  .ant-steps-item {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: $primary-purple-color !important;
        border-color: $primary-purple-color !important;

        .ant-steps-icon {
          color: white;
        }
      }
    }
  }
  .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: white !important;
        border-color: $primary-purple-color !important;

        .ant-steps-icon {
          .anticon.anticon-check.ant-steps-finish-icon {
            color: $primary-purple-color !important;
          }
        }
      }
    }
  }
  .ant-steps-item.ant-steps-item-wait {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        background-color: white !important;
        border-color: rgba(0, 0, 0, 0.45) !important;

        .ant-steps-icon {
          color: rgba(0, 0, 0, 0.45) !important;
        }
      }
    }
  }
}
button {
  .ant-spin {
    margin-left: 0.5rem;

    .ant-spin-dot-spin {
      font-size: 14px !important;
    }

    .ant-spin-dot-item {
      height: 7px;
      width: 7px;
      background-color: white !important;
    }
  }
}
.ant-tabs-bar {
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
}
.ant-list-bordered .ant-list-item {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.ant-list-bordered .ant-list-item:hover {
  background: whitesmoke;
  font-weight: bold;
}
.active-link-am {
  background: $primary-purple-color;
  font-weight: bold;
  color: white;

  &:hover {
    background: $primary-purple-color !important;
    font-weight: bold !important;
    color: white !important;
  }
}
h1.mainheader {
  text-transform: uppercase;
  width: 100%;
  color: $primary-purple-color;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.5;
  margin: 2rem 0;
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  clear: both;
}
.ant-table-thead > tr > th {
  color: $primary-purple-color;
  font-weight: bold !important;
  text-transform: uppercase;
  background: $table-primary-color;
  color: $table-font-color;
  border-bottom: 4px solid $primary-purple-color;
}
.ant-table-row {
  &:nth-child(even) {
    background-color: $td-background-color;
  }
  &:hover {
    background-color: $td-background-color;
  }
}
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: $td-background-color;
}
.annotation-canvas-table {
  ::-webkit-scrollbar-thumb {
    background-color: $primary-purple-color !important;
    border-radius: 7px !important;
  }
  tr.selectable-ac > td {
    background-color: $primary-purple-color !important;
    color: white !important;
  }
}
.ant-table-row:nth-child(odd) {
  background-color: $table-primary-color;
  &:hover {
    background-color: $td-background-color;
  }
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 10px !important;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 10px !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: none !important;
  border-radius: 10px !important;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $primary-purple-color !important;
  border-radius: 7px !important;
}

.custom-table {
  table {
    border-collapse: collapse;
    color: #321850;
    border-radius: 10px 10px 0 0;
    border-style: hidden;
  }
  thead {
    border-bottom: 3px solid #321850;
    background: white;
  }
  thead tr th {
    height: 0px;
  }

  th,
  td {
    width: 200px;
    height: 25px;
    text-align: left;
    padding: 8px;
    padding: 16px 16px;
  }

  tr:nth-child(odd) {
    background-color: white;
  }
  tr:nth-child(even) {
    background-color: #ebe8ee;
  }
  table thead tr th:first-child {
    border-radius: 10px 0 0 0 !important;
  }
  table thead tr th:last-child {
    border-radius: 0 10px 0 0 !important;
  }

  tbody tr:hover {
    background-color: #ebe8ee !important;
  }
}

.selectable {
  cursor: pointer;
}

.ant-descriptions {
  padding: 15px;
}

.ant-menu-inline {
  border-right: none;
}

.ant-menu-item:hover {
  color: #321850;
}

.ant-menu-item:active {
  background-color: #ebe8ee;
}

.ant-menu-item-selected {
  color: #321850 !important;
}

.ant-menu-submenu-title,
.ant-menu-submenu-title:hover {
  color: #321850;
}

.ant-menu-submenu-title:active {
  background-color: #f5f3f6 !important;
  color: #321850;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f5f3f6 !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #321850 !important;
}

.ant-menu-submenu-arrow {
  color: #321850 !important;
  background-color: #321850 !important;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(#321850), to(#321850));
  background: linear-gradient(to right, #321850, #321850);
}

.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: -webkit-gradient(linear, left top, right top, from(#321850), to(#321850));
  background: linear-gradient(to right, #321850, #321850);
}

.ant-btn:hover {
  border-color: #321850;
  color: #321850;
}

.ant-descriptions-view {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.anticon {
  vertical-align: 0 !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $primary-purple-color !important;
}

.ant-tabs-nav .ant-tabs-tab {
  color: $primary-purple-color !important;
}
.ant-tabs-ink-bar {
  background-color: $primary-purple-color !important;
}

.modal-container {
  margin: auto !important;

  .ant-modal-body {
    height: 70% !important;
    overflow: auto !important;
  }
}

@media only screen and (max-width: 1300px) {
  .modal-container {
    margin: auto !important;

    .ant-modal-body {
      height: 60vh !important;
      overflow: auto !important;
    }
  }
}

.ant-table-expanded-row {
  background: $gray-darker !important;
}

.ant-switch-checked {
  background-color: $primary-purple-color !important;
}

.isDisabledToolbar {
  pointer-events: none !important;
}
.ant-notification-notice-message {
  color: $primary-purple-color !important;
}
.ant-modal-body {
  padding: 15px !important;
}
.ant-form-item-control {
  max-width: 100%;
  width: 100%;
  white-space: normal;
}

.ant-tabs-nav-wrap {
  justify-content: center;
}
.ant-tabs-tab {
  &:focus {
    outline: none;
  }
}
.ant-layout-header {
  background: $gray-darker !important;
}
.ant-layout {
  height: 100% !important;
}
.ant-layout-content {
  padding: 0 1%;
  height: 100% !important;
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}
.ant-spin.ant-spin-sm.ant-spin-spinning {
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="text"]:disabled {
  background: white;
  color: rgba(0, 0, 0, 0.85) !important;
}
.customElementsDisabled {
  background: white !important;
  color: rgba(0, 0, 0, 0.85) !important;

  .ant-select-selector {
    background: white !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  div.ant-picker-input {
    input {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  }
}

.ant-table-cell {
  .alignRight {
    justify-content: flex-end;
    display: flex;
    margin-right: 5px;
  }
  .label-cell{
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    cursor: help;
    &:hover::after {
      content: attr(data-tooltip);
      position: absolute;
      white-space: pre-wrap;
      background-color: #f9f9f9;
      color: black;
      border: 1px solid #d3d3d3;
      padding: 5px;
      z-index: 1000;
      bottom: 80%;
      left: 0;
    }
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: $primary-purple-color !important;
  color: white !important;

  &::after {
    background-color: $primary-purple-color !important;
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-purple-color !important;
  border-color: $primary-purple-color !important;
  color: white !important;
}

.ant-upload-list.ant-upload-list-text {
  display: none;
}

.inner-table-pagination {
  .ant-pagination-total-text {
    margin-left: 25px;
    margin-right: auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.85);
    -webkit-transition: none;
    transition: none;
    font-weight: 500;
    background: #fff;
    min-width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
  }
}

.repair-module {
  ul.ant-pagination {
    margin-top: 20px;
  }
  .ant-pagination-total-text {
    margin-left: 32px;
  }
}

.bidder-table {
  min-height: 350px;
  .ant-pagination-total-text {
    margin-right: auto;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.85);
    -webkit-transition: none;
    transition: none;
    font-weight: 500;
    background: #fff;
    min-width: 32px !important;
    height: 32px !important;
    line-height: 30px !important;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
  }

  .ant-pagination-next,
  .ant-pagination-prev,
  .ant-pagination-item {
    min-width: 32px !important;
    height: 32px !important;
    margin-right: 8px !important;
    line-height: 30px !important;
  }

  .ant-pagination-item-link {
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }
}

.ant-pagination-total-text {
  margin-right: auto;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  -webkit-transition: none;
  transition: none;
  font-weight: 500;
  background: #fff;
  min-width: 32px;
  height: 32px;
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
}

@media only screen and (max-width: 1300px) {
  .bid-details {
    .ant-card-body {
      padding: 24px 10px !important;
    }
  }
}

.finding-card {
  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      text-transform: uppercase;
      font-size: 20px;
      padding: 10px 0 !important;
      font-weight: 600;
      color: $primary-purple-color;
    }

    .ant-card-extra {
      a {
        padding: 10px 0 !important;
        color: $primary-purple-color;
      }
    }
  }

  .ant-card-body {
    padding: 10px 24px;
  }
}

.ant-table-expanded-row .ant-table-expanded-row-level-1 {
  height: 300px;

  td {
    padding: 0 !important;
  }
}

.severity {
  display: flex;
  width: 30px;
  height: 22px;
  padding: 0px 8px;
  clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
  color: white !important;
  background: rgb(185, 185, 185);
  margin-left: 10px;
  opacity: 1 !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: $primary-purple-color !important;
  border-color: $primary-purple-color !important;
}

.ant-radio {
  color: $primary-purple-color !important;

  .ant-radio-inner {
    border-color: $primary-purple-color !important;
  }

  .ant-radio-inner::after {
    border-color: $primary-purple-color !important;
    background-color: $primary-purple-color !important;
  }
}

.confirm-dialog {
  height: 250px !important;

  modal-close:hover {
    color: $primary-purple-color !important;
    text-decoration: none;
  }

  .ant-modal-close-x {
    margin-top: 5px;
  }

  .ant-modal-confirm-title {
    font-size: 20px;
    color: $primary-purple-color;
  }

  .ant-btn-primary {
    background: $primary-purple-color !important;
    border-color: $primary-purple-color !important;

    &:focus {
      border-color: $primary-purple-color !important;

      span {
        color: white !important;
      }
    }

    &:hover {
      background-color: $purple-light !important;
      border-color: $purple-light !important;

      span {
        color: white !important;
      }
    }
  }
}

.ant-table-thead th.ant-table-column-sort {
  background-color: transparent !important;
}

td.ant-table-column-sort {
  background-color: transparent !important;
}

.repairListTable {
  .ant-table-body {
    height: 47vh;
  }
}

.ant-page-header-heading-extra > span {
  padding: 0 !important;
  border: none !important;
}

.QBRHeader {
  span.ant-page-header-heading-title {
    font-size: 1.5rem;
    font-weight: 500;
  }
}

//QUOTE FORM

.quoteForm {
  > div:last-child {
    margin-top: 110px;
  }
  .ant-row {
    display: flex;
    justify-content: flex-start;

    .ant-col-xs-8 {
      max-width: 350px;
      flex: 0 0 37%;
    }
    .ant-picker {
      width: 100%;
    }
  }
}

.ant-slider-track {
  background-color: $purple-light;
}

.ant-slider-track:hover {
  background-color: $primary-purple-color;
}

.ant-slider-handle{
  border: solid 2px $purple-light;
}

.ant-slider-handle:focus{
  border: solid 2px $primary-purple-color;
}

.ant-slider-handle-dragging,
.ant-slider-handle-click-focused {
  border: solid 2px $primary-purple-color;
}

.ant-slider-handle-click-focused:hover {
  border: solid 2px $primary-purple-color;
}

.ant-slider:hover
.ant-slider-track{
  background-color: $primary-purple-color;
}

.ant-slider-handle.ant-tooltip-open {
  border-color: $primary-purple-color;
}

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: $primary-purple-color;
  box-shadow: 0 0 0 5px #d6bbf5a1;
}

.ant-slider:hover
.ant-slider-handle:not(.ant-tooltip-open) {
  border-color: $purple-light;
}

html{
  --antd-wave-shadow-color: $purple-light
}

.ant-tooltip{
  z-index: 1000 !important;
}